import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme } from './theme';
import { configureStore } from './store';
import routes from './routes';
import TagManager from 'react-gtm-module';
// import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import { history } from './utils/history';
import {IntlProvider} from 'react-intl';
import AppLocale from './lngProvider';

// const history = createBrowserHistory();
const store = configureStore();

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const tagManagerArgs = {
  gtmId: `${GA_MEASUREMENT_ID}`
}
TagManager.initialize(tagManagerArgs);

function App() {
  const [direction, setDirection] = useState('ltr');
  const currentAppLocale = AppLocale['ja'];
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <StylesProvider direction={direction}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <Router history={history}>
              <ScrollReset />
              {/* <CookiesNotification /> */}
              {renderRoutes(routes)}
            </Router>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
