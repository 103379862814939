/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/customers" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/mail',
        exact: true,
        component: lazy(() => import('src/views/MailLogin'))
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/social',
    component: AuthLayout,
    routes: [
      {
        path: '/social/login/line',
        exact: true,
        component: lazy(() => import('src/views/SocialLogin'))
      },
      {
        path: '/social/register/line/:token/:email/:name',
        exact: true,
        component: lazy(() => import('src/views/Register/LineAccount'))
      },
      {
        path: '/social/register/line/:token',
        exact: true,
        component: lazy(() => import('src/views/Register/RegisterEmail'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/user',
    component: AuthLayout,
    routes: [
      {
        path: '/user/register',
        exact: true,
        component: lazy(() => import('src/views/Register/RegisterEmail'))
      },
      {
        path: '/user/verify',
        exact: true,
        component: lazy(() => import('src/views/Register/RegisterEmail/Success'))
      },
      {
        path: '/user/verification/:token',
        exact: true,
        component: lazy(() => import('src/views/Register/RegisterAccount'))
      },
      {
        path: '/user/register/success',
        exact: true,
        component: lazy(() => import('src/views/Register/RegisterAccount/Success'))
      },
      {
        path: '/user/forgot_password',
        exact: true,
        component: lazy(() => import('src/views/ResetPassword/Email'))
      },
      {
        path: '/user/forgot_password/success',
        exact: true,
        component: lazy(() => import('src/views/ResetPassword/Email/success'))
      },
      {
        path: '/user/password/reset/:token',
        exact: true,
        component: lazy(() => import('src/views/ResetPassword/Reset'))
      },
      {
        path: '/user/password/success',
        exact: true,
        component: lazy(() => import('src/views/ResetPassword/Reset/success.js'))
      },
      {
        path: '/user/verify-email',
        exact: true,
        component: lazy(() => import('src/views/Register/VerifyEmail'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/customers',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList'))
      },
      {
        path: '/customers/add',
        exact: true,
        component: lazy(() => import('src/views/CreateCustomer'))
      },
      {
        path: '/customers/:id/profile/:tab',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/orders',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementList'))
      },
      {
        path: '/orders/add',
        exact: true,
        component: lazy(() => import('src/views/CreateOrder'))
      },
      {
        path: '/orders/:id/detail',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementDetails'))
      },
      {
        path: '/debitManagement',
        exact: true,
        component: lazy(() => import('src/views/DebitManagement'))
      },
      ,
      {
        path: '/reports',
        exact: true,
        component: lazy(() => import('src/views/Reports'))
      },
      {
        path: '/reports/:status/:id/:mounth',
        exact: true,
        component: lazy(() => import('src/views/ReportsDetail'))
      },
      {
        path: '/settings/mypage',
        exact: true,
        component: lazy(() => import('src/views/Setting/MyPage'))
      },
      {
        path: '/settings/display',
        exact: true,
        component: lazy(() => import('src/views/Setting/Display'))
      },
      {
        path: '/settings/bottle',
        exact: true,
        component: lazy(() => import('src/views/Setting/Bottle'))
      },
      {
        path: '/settings/category',
        exact: true,
        component: lazy(() => import('src/views/Setting/BottleCategory'))
      },
      {
        path: '/settings/cast',
        exact: true,
        component: lazy(() => import('src/views/Setting/Cast'))
      },
      {
        path: '/settings/integratecustomer',
        exact: true,
        component: lazy(() => import('src/views/Setting/IntegrateCustomer'))
      },
      {
        path: '/settings/integratecast',
        exact: true,
        component: lazy(() => import('src/views/Setting/IntegrateCast'))
      },
      {
        path: '/settings/staff',
        exact: true,
        component: lazy(() => import('src/views/Setting/Staff'))
      },
      {
        path: '/settings/bar',
        exact: true,
        component: lazy(() => import('src/views/Setting/Bar'))
      },
      {
        path: '/settings/customer-data/:tab',
        exact: true,
        component: lazy(() => import('src/views/Setting/CustomerData'))
      },
      {
        path: '/logout',
        exact: true,
        component: lazy(() => import('src/views/Logout'))
      },
      {
        path: '/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/Profile'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
