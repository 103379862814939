/**
 * Created by ducnn on 1/3/20.
 */
export const GlobalConstants = {
  USER_KEY: 'crt.APP_NAME.user',
  LINE_AUTH_STATE_KEY: 'line.auth.state',
  LINE_AUTH_NONCE_KEY: 'line.auth.nonce',
  EMAIL_VERIFY_URL: 'email.verify.url',
  API_URL: 'http://localhost/api',
  BASE_URL: 'http://localhost',
};
