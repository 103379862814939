/**
 * Created by ducnn on 1/3/20.
 */
import axios from 'axios'
import { GlobalConstants } from '../constants'
import { history } from './history'


const API_URL = (process.env.NODE_ENV === 'development') ? (`http://localhost/api/`) : `/api/`;


axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem(GlobalConstants.USER_KEY);
      history.push('/');
    }
    if (error.response.status === 500) {
      history.push('/errors/error-500');
    }
    return Promise.reject(error);
  });

export default axios
