/**
 * Created by ducnn on 1/3/20.
 */
import { userConstants, GlobalConstants } from '../constants';

let user = JSON.parse(localStorage.getItem(GlobalConstants.USER_KEY));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        user: action.error
      };
    case userConstants.LOGOUT_REQUEST:
      return {};
    case userConstants.LOGOUT_SUCCESS:
      return {};
    case userConstants.LOGOUT_FAILURE:
      return {};
    default:
      return state
  }
}

