import appLocaleData from 'react-intl/locale-data/ja';
import jaMessages from '../locales/ja_JP.json';

const JaLang = {
  messages: {
    ...jaMessages
  },
  locale: 'en-US',
  data: appLocaleData
};
export default JaLang;
