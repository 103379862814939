import mock from 'src/utils/mock';

mock.onGet('/customers/1/profile').reply(200, {
    "isSuccess": true,
    "data": {
        "customer_id": 1,
        bar_name: "店舗AAA(略称3A)",
        "bottles": [
            {
                "bar_name": "店舗名	",
                "id": 1,
                "remain": 100,
                "created_at": "2019/03/10",
                "name": "bottle1",
                "code": "001"
            },
            {
                "bar_name": "店舗名	",
                "id": 1,
                "remain": 100,
                "created_at": "2019/04/12",
                "name": "bottle2",
                "code": "002"
            },
            {
                "bar_name": "店舗名	",
                "id": 1,
                "remain": 100,
                "created_at": "2019/10/12",
                "name": "bottle3",
                "code": "003"
            },
            {
                "bar_name": "店舗名	",
                "id": 1,
                "remain": 100,
                "created_at": "2019/12/12",
                "name": "bottle3",
                "code": "004"
            }
        ],
        "customer_name": "中田 耕介",
        "furigana_name": "なかだ こうすけ",
        "icon": "asdad",
        "company_name": "株式会社モトアップ",
        "part": "設定する",
        "company_provine": "東京都",
        "company_district": "新宿区西新宿",
        "company_street": "3-9-23",
        "castInCharge": "ママ(キャスト一覧から選択)",
        "favoriteRank": 0,
        "incomeRank": 0,
        "created_at": null,
        "updated_at": null,
        "dateOfBirth": "1988/01/29",
        "email": "hoge@hoge.com",
        "phone": "0969300468",
        "lineID": "hogehoge",
        "department": "Set",
        "position": "CTO",
        "job": "IT",
        "post_code": "1600023",
        "company_tower": "3Mien",
        "keep_bottle_limit_day": 160,
        "note": "This is the note",
        "day_of_week_can_be_contact": "可 or 不可のプルダウン"
    },
    "message": "Get customer successful"
});