/**
 * Created by ducnn on 1/3/20.
 */
import { userConstants } from '../constants';
import { userService } from '../services';
import { history } from '../utils/history';
import { alertActions } from './alertActions';

export const userActions = {
  loginRedirect,
  logout,
  refreshToken
};

function loginRedirect(form) {
  return async dispatch => {
    dispatch(request({ email: form.email }));

      let response = await userService.login(form);
      if (response.isSuccess) {
        await dispatch(success(response.data));
        history.push('/customers');
      } else {
        dispatch(failure(response.errors));
        dispatch(alertActions.error(response.errors));
      }
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  return dispatch => {
    dispatch(request({ }));

    userService.logout()
      .then(response => {
          dispatch(success());
          // history.push('/');
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGOUT_REQUEST, user } }
  function success() { return { type: userConstants.LOGOUT_SUCCESS } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function refreshToken() {
  return dispatch => {
    dispatch(request({ }));

    userService.refreshToken()
      .then(response => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(user) { return { type: userConstants.REFRESH_TOKEN_REQUEST, user } }
  function success() { return { type: userConstants.REFRESH_TOKEN_SUCCESS } }
  function failure(error) { return { type: userConstants.REFRESH_TOKEN_FAILURE, error } }
}
