import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { GlobalConstants } from '../../constants';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { userActions } from 'src/actions';
import Http from 'src/utils/Http';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'visible',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Dashboard({ route }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const user = JSON.parse(localStorage.getItem(GlobalConstants.USER_KEY));
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(userActions.logout());
    history.push('/auth/login');
  };

  const resend = () => {
    Http.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem(GlobalConstants.USER_KEY)).token;
    Http.post('/email/resend').then(response => {
    });
  }

  const getUser = () => {
    Http.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem(GlobalConstants.USER_KEY)).token;
    Http.get('/user/details').then(response => {
      let currentUser = response.data.data;
      if (currentUser.verified) {
        user.user = currentUser;
        localStorage.setItem(GlobalConstants.USER_KEY, JSON.stringify(user));
        history.push('/customers');
      }
    });
  }

  if (!user) {
    return <Redirect to="/auth/mail" />
  }

  if (!user.user.verified) {
    getUser();
    return (
      <>
        <div>
          Email does not verify! Please check email and verify.
        </div>
        <button onClick={logout}>Logout</button>
        <br />
        <button onClick={resend}>Resend Email Verify</button>
      </>
    );
  }

  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
