/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Component } from 'react'
import PersonIcon from '@material-ui/icons/PersonOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import InputIcon from '@material-ui/icons/InputOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { GlobalConstants } from 'src/constants';

export default class NavConfig extends Component {
  constructor(props) {
    super(props);
  }


  getListNavBar() {
    const user = JSON.parse(localStorage.getItem(GlobalConstants.USER_KEY));
    const commonPages = {
      customerPage: {
        title: '顧客管理',
        href: '/customers',
        icon: PeopleIcon,
        items: [
          { title: '顧客一覧', href: '/customers' },
          { title: '顧客追加', href: '/customers/add' }
        ]
      },
      orderPage: {
        title: '来店管理',
        href: '/orders',
        icon: PersonIcon,
        items: [
          { title: '来店一覧', href: '/orders' },
          { title: '来店追加', href: '/orders/add' }
        ]
      },
      debitManagement: {
        title: '売掛管理',
        href: '/debitManagement',
        icon: AttachMoneyIcon
      },
      settingPage: {
        title: '設定',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          { title: 'マイページ', href: '/settings/mypage' },
          { title: '表示設定', href: '/settings/display' },
          { title: 'ボトルカテゴリ管理', href: '/settings/category' },
          { title: 'ボトル設定', href: '/settings/bottle' },
          { title: 'キャスト設定', href: '/settings/cast' }
        ]
      },
      reportPage: {
        title: 'レポート',
        href: '/reports',
        icon: AssessmentIcon
      },
      logoutPage: {
        title: 'ログアウト',
        href: '/logout',
        icon: InputIcon
      }
    };

    const additionalItems = {
      manager: [
        { title: 'キャストデータ統合', href: '/settings/integratecast' },
        { title: '顧客データ統合', href: '/settings/integratecustomer' },
        { title: '顧客データ管理', href: '/settings/customer-data/delete' }
      ],
      1: [
        { title: 'キャストデータ統合', href: '/settings/integratecast' },
        { title: '店舗管理', href: '/settings/bar' },
        { title: '顧客データ統合', href: '/settings/integratecustomer' },
        { title: '顧客データ管理', href: '/settings/customer-data/delete' }
      ],
      2: [
        { title: 'キャストデータ統合', href: '/settings/integratecast' },
        { title: '店舗管理', href: '/settings/bar' },
        { title: '顧客データ統合', href: '/settings/integratecustomer' },
        { title: '顧客データ管理', href: '/settings/customer-data/delete' }
      ],
      3: [
        { title: 'キャストデータ統合', href: '/settings/integratecast' },
        { title: 'スタッフ設定', href: '/settings/staff' },
        { title: '店舗管理', href: '/settings/bar' },
        { title: '顧客データ統合', href: '/settings/integratecustomer' },
        { title: '顧客データ管理', href: '/settings/customer-data/delete' }
      ],
      4: [
        { title: 'キャストデータ統合', href: '/settings/integratecast' },
        { title: 'スタッフ設定', href: '/settings/staff' },
        { title: '店舗管理', href: '/settings/bar' },
        { title: '顧客データ統合', href: '/settings/integratecustomer' },
        { title: '顧客データ管理', href: '/settings/customer-data/delete' }
      ],
      admin: [
        { title: 'キャストデータ統合', href: '/settings/integratecast' },
        { title: 'スタッフ設定', href: '/settings/staff' },
        { title: '店舗管理', href: '/settings/bar' },
        { title: '顧客データ統合', href: '/settings/integratecustomer' },
        { title: '顧客データ管理', href: '/settings/customer-data/delete' }
      ]
    }

    const role = user.user.role;
    const account_limit_plan = user.user.account_limit_plan;
    const account_limit_plan_id = user.user.account_limit_plan_id;
    const isAdmin = user.user.is_admin;
    let item = []

    item[1] = commonPages.customerPage;
    item[2] = commonPages.orderPage;
    item[3] = commonPages.debitManagement;
    item[4] = commonPages.settingPage;
    if (account_limit_plan !== 'free') { item[5] = commonPages.reportPage }
    item[6] = commonPages.logoutPage;

    if (isAdmin) {
      commonPages.settingPage.items.push(...additionalItems['admin'])
    } else if (role === 'owner' && isAdmin !== 1) {
      commonPages.settingPage.items.push(...additionalItems[account_limit_plan_id])
    } else if (role === 'manager') {
      commonPages.settingPage.items.push(...additionalItems['manager'])
    }

    return [
      {
        subheader: '',
        items: item
      }
    ];
  }
}
